<template>
  <div>
    <div class="text-white mt-4 text-center flex">
      <div class="grid md:grid-cols-2 md:gap-16 mx-auto">
        <b v-for="(item, key) in languages" :key="key" @click="setLocale(key)">
          <img :src="'/images/flags/' + key + '.png'" />
        </b>
      </div>
    </div>
    <footer :class="footerClass">
      <div class="border-t py-4" :class="borderClass">
        <p
          class="text-center text-sm leading-6 text-slate-500 dark:text-gray-300"
        >
          &copy; {{ theYear }} {{ company }}. All rights reserved. <br />
          {{ $t('contact_us') }}
          <a href="mailto:server@openverse.network">server@openverse.network</a
          ><br /><br />
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'AppFooter',
  components: {},
  props: {
    footerClass: {
      type: String,
      default: 'max-w-container mx-auto mt-4 w-full px-4 sm:px-6 lg:px-8',
    },
    borderClass: {
      type: String,
      default: 'border-slate-900/5 dark:border-slate-700',
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      lang: 'en-US',
      company: 'Openverse team',
    };
  },
  computed: {
    ...mapState(['languages']),
    theYear() {
      const startYear = '2022';
      const currentYear = new Date().getFullYear();
      if (currentYear == startYear) {
        return currentYear;
      }
      return `${startYear}-${currentYear}`;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.lang = this.$i18n.locale;
  },
  beforeUnmount() {},
  updated() {},
  methods: {
    setLocale(key) {
      this.$i18n.locale = key;
      localStorage.setItem('language', key);
      this.lang = key;
    },
  },
};
</script>

<style scoped></style>

<style lang="scss" scoped></style>
