<template>
  <div class="min-h-screen main_bg">
    <AppNav v-if="!isAdminPage" />
    <div class="w-full">
      <router-view v-slot="{ Component }" :class="isAdminPage ? '' : 'p-6'">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <AppFooter v-if="!isAdminPage" class="top-[100vh] w-full" />
    <AppToast />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppNav from '@components/AppNav.vue';
import AppFooter from '@components/AppFooter.vue';
import VerifyNotice from '@components/VerifyNotice.vue';

export default {
  components: {
    AppNav,
    VerifyNotice,
    AppFooter,
  },
  computed: {
    ...mapGetters({
      darkMode: 'auth/darkMode',
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isAdminPage() {
      if (
        this.currentRouteName == 'admin' ||
        this.currentRouteName == 'roles' ||
        this.currentRouteName == 'permissions' ||
        this.currentRouteName == 'settings' ||
        this.currentRouteName == 'users' ||
        this.currentRouteName == 'app-settings' ||
        this.currentRouteName == 'phpinfo'
      ) {
        return true;
      }
      return false;
    },
    showOctoCat() {
      if (
        this.currentRouteName == 'home' ||
        this.currentRouteName == 'about' ||
        this.currentRouteName == 'terms'
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.main_bg {
  /* background: #120b3e url('/images/header_bg.webp') no-repeat 50% 0; */
  background: #120b3e;
}
.border-purple {
  border-color: #2f1984 !important;
}
.bg-purple {
  background-color: #0a082d !important;
}

.status_success {
  color: green;
}

.status_info {
  color: blue;
}

.status_fail {
  color: red;
}

.hero_section {
  background-image: linear-gradient(rgba(28, 13, 64, 0) 81%, #0b0726),
    linear-gradient(to right, #0a061e, rgba(17, 15, 59, 0) 68%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.loader {
  width: 120px;
  height: 16px;
  border-radius: 14px;
  color: #fff;
  border: 2px solid;
  position: relative;
}
.loader::before {
  content: '';
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 2s infinite;
}
.box-rounded {
  border-radius: 1rem !important;
}
body {
  font-size: 75%;
}

@keyframes l6 {
  100% {
    inset: 0;
  }
}
</style>
